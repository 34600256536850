const nameServerRegEx = /^([a-zA-Z0-9_]|[a-zA-Z0-9_][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9_])(?:\.([a-zA-Z0-9_]|[a-zA-Z0-9_][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9_]))+[.]{0,1}$/
const nameServerMxPattern = new RegExp(nameServerRegEx, 'i')

export function validateNameServer(nameServers: string[]) {
  const nsList = nameServers.map((ns) =>
    ns.endsWith('.') ? ns.slice(0, ns.length - 1) : ns
  )

  if (nsList.length > 13) {
    return {
      status: false,
      errorMsg: 'Must be equal or less than 13 name servers',
    }
  }

  // Check duplicated NS records
  const sets = new Set(nsList)

  if (sets.size !== nsList.length) {
    return { status: false, errorMsg: 'Duplicated ns record' }
  }

  const errorNs: string[] = []

  nsList.forEach((ns) => {
    if (ns.length > 253 || !nameServerMxPattern.test(ns)) {
      errorNs.push(ns)
    }
  })

  if (errorNs.length > 0) {
    return { status: false, errorMsg: 'Invalid name servers', errorNs }
  }

  return { status: true }
}
