import React, { useEffect } from 'react'
import useTranslation from '@/i18n'
import NameServer from './NameServer'
import { useProgress, useSafeState, useErr } from '@/hooks'
import { parseNameServers } from '@/parse'
import { validateDsRecord, validateNameServer } from '@/validation'
import ErrorMsg from '@/components/Parts/ErrorMsg'
import { post } from '@/api/domain/config'
import { DomainConfigResult, DomainConfigParams } from '@/types'
import ButtonRounded from '@/components/Parts/ButtonRounded'
import FlexList from '@/components/Parts/FlexListSimple'

type Props = {
  config: DomainConfigResult
  update: (result: DomainConfigResult) => void
  reload: () => Promise<void>
}

function External(props: Props) {
  const { t } = useTranslation()
  const [dsList, setDsList] = useSafeState(
    props.config.signing_keys.length > 0 ? props.config.signing_keys : ['']
  )
  const [ns, setNs] = useSafeState('')
  const [dsError, setDsError] = useSafeState('')
  const [nsError, setNsError] = useSafeState('')
  const { start, stop } = useProgress()
  const [error, setError] = useSafeState<{ id: number; msg: string }[]>([])
  const { setErr } = useErr()

  useEffect(() => {
    setNs(props.config.name_servers.join('\n'))
  }, [props.config])

  const errorHandler = (err: any) => {
    if (err != null && err.status === 422) {
      switch (err.subCode) {
        case 7:
        case 3:
        case 8:
          setDsError('Invalid DS record or name servers')
          return
        case 9: // Our validation from backend
          setError([
            ...err.params.map((id) => {
              return { id, msg: 'Invalid ds record' }
            }),
          ])
          setDsList([...dsList])
          return
        case 10: // From JPRS. We can not get which ds is wrong
        case 12:
          setDsError(err.message)
          return
        case 11:
        case 13:
          setNsError(err.message)
          return
        case 14:
          console.log(err)
          return
      }
    }
    setErr(err.status, err.message)
  }

  const onClick = () => {
    // dsList.forEach((ds) => (ds.isError = false))
    setDsList([...dsList])
    setDsError('')
    setNsError('')
    setError([])

    const nameServers = parseNameServers(ns)
    const res = validateNameServer(nameServers)

    if (!res.status && res.errorMsg != null) {
      setNsError(res.errorMsg)
      return
    }

    const signing_keys = dsList.filter((ds) => ds.length > 0)

    if (signing_keys.some((ds) => !validateDsRecord(ds))) {
      setDsError('Invalid ds records')
      return
    }

    const params: DomainConfigParams = {
      id: props.config.id,
      use_qt_dns: false,
      name_servers: nameServers.map((ns) =>
        ns.endsWith('.') ? ns.slice(0, ns.length - 1) : ns
      ),
      signing_keys,
    }

    start(0)
    post(params)
      .then(() => {
        props
          .reload()
          .then(() => stop())
          .catch(() => stop())
      })
      .catch((err) => {
        errorHandler(err)
        stop()
      })
  }

  return (
    <div className={`w-full`}>
      <div>
        {t('ExplainDomainManagerForExternal')}
        <span className="text-red-500 font-medium">
          {t('WarningConfigNameServer')}
        </span>
        {t('AdvertisementQTDns')}
      </div>
      <NameServer text={ns} onChange={setNs} error={nsError} />
      <FlexList
        title={'DS records'}
        vals={dsList}
        errorMsg={error}
        change={(ds: string[]) => {
          setDsList([...ds])
        }}
        validate={validateDsRecord}
        required={false}
      />

      <ErrorMsg error={dsError} />
      <div className="mt-8 text-right">
        <ButtonRounded name="Update" onClick={onClick} disabled={false} />
      </div>
    </div>
  )
}

export default External
