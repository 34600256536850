import React from 'react'
import useTranslation from '@/i18n'
import UserInput from '@/components/Parts/UserInput'
import UserInputs from '@/components/Parts/UserInputs'
import { useProgress, useSafeState, useErr, useTooltip } from '@/hooks'
import { RegistrantAtrributeResult } from '@/types'

const _defaultConfig: RegistrantAtrributeResult = {
  AdminContact: '',
  TechContact: '',
  OrganizationName: '',
  OrganizationNameKana: '',
  OrganizationNameEn: '',
  OrganizationType: '',
  OrganizationTypeEn: '',
  OrganizationPostalCode: '',
  OrganizationAddress: '',
  OrganizationAddressEn: '',
  OrganizationRegistrationDate: '',
  OrganizationRegistrationAddress: '',
  OrganizationReplyMail: [],
  ServiceProviderName: '',
  RepresentativeOrganizationName: '',
  RepresentativeLastName: '',
  RepresentativeFirstName: '',
  RepresentativeLastNameEn: '',
  RepresentativeFirstNameEn: '',
  ViceRepresentativeOrganizationName: '',
  ViceRepresentativeLastName: '',
  ViceRepresentativeFirstName: '',
  ViceRepresentativeLastNameEn: '',
  ViceRepresentativeFirstNameEn: '',
  ViceRepresentativeOrganizationRegistrationDate: '',
  ViceRepresentativeOrganizationRegistrationAddress: '',
  ApplicantOrganizationName: '',
  ApplicantLastName: '',
  ApplicantFirstName: '',
  ApplicantLastNameEn: '',
  ApplicantFirstNameEn: '',
  ApplicantTitle: '',
  ApplicantRegistrationDate: '',
  ApplicantRegistrationAddress: '',
}

type Invalids = {
  [key: string]: {
    message: string
    params?: any
  }
}

const _check = (val?: string) => (val == null ? '' : val)

const _defaultInvalids: Invalids = {
  ['AdminContact']: { message: '' },
  ['TechContact']: { message: '' },
  ['OrganizationName']: { message: '' },
  ['OrganizationNameKana']: { message: '' },
  ['OrganizationNameEn']: { message: '' },
  ['OrganizationType']: { message: '' },
  ['OrganizationTypeEn']: { message: '' },
  ['OrganizationPostalCode']: { message: '' },
  ['OrganizationAddress']: { message: '' },
  ['OrganizationAddressEn']: { message: '' },
  ['OrganizationRegistrationDate']: { message: '' },
  ['OrganizationRegistrationAddress']: { message: '' },
  ['OrganizationReplyMail']: { message: '' },
  ['ServiceProviderName']: { message: '' },
  ['RepresentativeOrganizationName']: { message: '' },
  ['RepresentativeLastName']: { message: '' },
  ['RepresentativeFirstName']: { message: '' },
  ['RepresentativeLastNameEn']: { message: '' },
  ['RepresentativeFirstNameEn']: { message: '' },
  ['ViceRepresentativeOrganizationName']: { message: '' },
  ['ViceRepresentativeLastName']: { message: '' },
  ['ViceRepresentativeFirstName']: { message: '' },
  ['ViceRepresentativeLastNameEn']: { message: '' },
  ['ViceRepresentativeFirstNameEn']: { message: '' },
  ['ViceRepresentativeOrganizationRegistrationDate']: { message: '' },
  ['ViceRepresentativeOrganizationRegistrationAddress']: { message: '' },
  ['ApplicantOrganizationName']: { message: '' },
  ['ApplicantLastName']: { message: '' },
  ['ApplicantFirstName']: { message: '' },
  ['ApplicantLastNameEn']: { message: '' },
  ['ApplicantFirstNameEn']: { message: '' },
  ['ApplicantTitle']: { message: '' },
  ['ApplicantRegistrationDate']: { message: '' },
  ['ApplicantRegistrationAddress']: { message: '' },
}

function RegistrantAttribute(props: RegistrantAtrributeResult) {
  const [configTemp, setConfigTemp] = useSafeState<RegistrantAtrributeResult>(
    _defaultConfig
  )
  const [config, setConfig] = useSafeState<RegistrantAtrributeResult>(
    _defaultConfig
  )
  const [invalids, setInvalids] = useSafeState({ ..._defaultInvalids })
  const [ready, setReady] = useSafeState(false)
  const [stateEn, setStateEn] = useSafeState('')
  const { t } = useTranslation()
  const { start, stop, inProgress } = useProgress()
  const { setErr } = useErr()
  const { setTooltip } = useTooltip()

  // useEffect(() => {
  //   const { CountryCode, ...rest } = props
  //   const CC = CountryCode == null ? 'JP' : CountryCode

  //   setStateEn(prefectures_en[Number(props.PrefectureCode) - 1])

  //   setConfigTemp({ CountryCode: CC, ...rest })
  // }, [])

  // useEffect(() => {
  //   load()
  // }, [])

  // const load = () => {
  //   start()
  //   get()
  //     .then((res) => {
  //       if (res.country_code.length === 0) {
  //         res.country_code = 'JP'
  //       }
  //       setConfigTemp({ ...res })
  //       setConfig({ ...res })
  //       setReady(validate(res))
  //       stop()
  //     })
  //     .catch((_) => {
  //       stop()
  //     })
  // }

  // const save = () => {
  //   if (inProgress) return

  //   Object.entries(invalids).forEach(([key, _]) => (invalids[key].message = ''))
  //   setInvalids({ ...invalids })

  //   Object.entries(config).forEach(
  //     ([key, value]) => (config[key] = value.trim())
  //   )

  //   if (config.country_code == 'JP') {
  //     config.address3 = ''
  //   }

  //   start()
  //   post(config)
  //     .then((res) => {
  //       setConfigTemp({ ...res })
  //       setConfig({ ...res })
  //       stop()
  //       setTooltip('Success')
  //     })
  //     .catch((err) => {
  //       if (err.message === 'Format error') {
  //         err.params.forEach(({ key, code }) => {
  //           invalids[key].message = formatErrorTable[code]
  //         })

  //         setInvalids({ ...invalids })
  //         setErr('Invalid parameter', 422)
  //       } else {
  //         setErr(err.message, err.status)
  //       }
  //       stop()
  //     })
  // }

  // const validate = (conf: ConfigOrganizationTemplateParam) => {
  //   if (conf.country_code === 'JP') {
  //     return !Object.entries(config).some(([key, value]) => {
  //       return (
  //         !['fax_number', 'division', 'address3'].includes(key) &&
  //         value.length === 0
  //       )
  //     })
  //   }

  //   return !Object.entries(config).some(([key, value]) => {
  //     return (
  //       !['fax_number', 'division', 'prefecture_code'].includes(key) &&
  //       value.length === 0
  //     )
  //   })
  // }

  // const update = (key: string, value: string, res: boolean) => {
  //   configTemp[key] = value
  //   setConfigTemp({ ...configTemp })

  //   if (res) {
  //     config[key] = configTemp[key]

  //     if (configTemp.country_code === 'JP') {
  //       const id = prefectures.findIndex(
  //         (pre) => pre === configTemp.prefecture_code
  //       )

  //       config.prefecture_code = configTemp.prefecture_code

  //       if (id >= 0) {
  //         config.address1_en = prefectures_en[id]
  //       }
  //     } else {
  //       config.prefecture_code = ''
  //       config.address1 = configTemp.address1_en
  //       config.address2 = configTemp.address2_en
  //       config.address3 = configTemp.address3_en
  //     }

  //     setConfig({ ...config })
  //   } else {
  //     config[key] = ''
  //     setConfig({ ...config })
  //   }

  //   setReady(validate(config))
  // }
  const load = () => {}

  const save = () => {}

  const update = (key: string, value: string, res: boolean) => {}

  return (
    <div className="w-full mx-auto">
      <h1 className="text-blue-600 font-medium text-2xl mb-4">
        {t('Registrant information')}
      </h1>

      <UserInput
        name="Organization name"
        max={120}
        pattern=".{1,120}"
        invalid={invalids.OrganizationName}
        value={props.OrganizationName}
        required={true}
        onChange={(value, res) => update('OrganizationName', value, res)}
      />

      <UserInput
        name="Organization name (Kana)"
        max={120}
        pattern=".{1,120}"
        invalid={invalids.OrganizationNameKana}
        value={props.OrganizationNameKana}
        required={true}
        onChange={(value, res) => update('OrganizationNameKana', value, res)}
      />

      <UserInput
        name="Organization name (En)"
        max={120}
        value={props.OrganizationNameEn}
        pattern=".{1,120}"
        invalid={invalids.OrganizationNameEn}
        required={true}
        onChange={(value, res) => update('OrganizationNameEn', value, res)}
      />

      <UserInput
        name="Organization type"
        max={120}
        value={props.OrganizationType}
        pattern=".{1,120}"
        invalid={invalids.OrganizationType}
        required={true}
        onChange={(value, res) => update('OrganizationType', value, res)}
      />

      <UserInput
        name="Organization type (En)"
        max={8}
        pattern=".{1,8}"
        invalid={invalids.OrganizationTypeEn}
        value={props.OrganizationTypeEn}
        required={true}
        onChange={(value, res) => update('OrganizationTypeEn', value, res)}
        placeholder="123-4567"
      />

      <UserInput
        name="Organization postal code"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.OrganizationPostalCode}
        value={props.OrganizationPostalCode}
        required={true}
        onChange={(value, res) => update('OrganizationPostalCode', value, res)}
        placeholder=""
      />

      <UserInput
        name="Organization address"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.OrganizationAddress}
        value={props.OrganizationAddress}
        required={true}
        onChange={(value, res) => update('OrganizationAddress', value, res)}
      />

      <UserInput
        name="Organization address (En)"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.OrganizationAddressEn}
        value={props.OrganizationAddressEn}
        required={true}
        onChange={(value, res) => update('OrganizationAddressEn', value, res)}
      />

      <UserInput
        name="Organization registration date"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.OrganizationRegistrationDate}
        value={props.OrganizationRegistrationDate}
        required={true}
        onChange={(value, res) =>
          update('OrganizationRegistrationDate', value, res)
        }
      />

      <UserInput
        name="Organization registration address"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.OrganizationRegistrationAddress}
        value={props.OrganizationRegistrationAddress}
        required={true}
        onChange={(value, res) =>
          update('OrganizationRegistrationAddress', value, res)
        }
      />

      <UserInputs
        name="Organization reply mails"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.OrganizationReplyMail}
        value={props.OrganizationReplyMail}
        required={true}
        onChange={(value, id, res) =>
          update('OrganizationReplyMail', value, res)
        }
      />

      <UserInput
        name="Service provider name"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.ServiceProviderName}
        value={_check(props.ServiceProviderName)}
        required={true}
        onChange={(value, res) => update('ServiceProviderName', value, res)}
      />

      <UserInput
        name="Representative organization name"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.RepresentativeOrganizationName}
        value={_check(props.RepresentativeOrganizationName)}
        required={true}
        onChange={(value, res) =>
          update('RepresentativeOrganizationName', value, res)
        }
      />

      <UserInput
        name="Representative last name"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.RepresentativeLastName}
        value={_check(props.RepresentativeLastName)}
        required={true}
        onChange={(value, res) => update('RepresentativeLastName', value, res)}
      />

      <UserInput
        name="Representative first name"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.RepresentativeFirstName}
        value={props.RepresentativeFirstName}
        required={true}
        onChange={(value, res) => update('RepresentativeFirstName', value, res)}
      />
      <UserInput
        name="Representative last name (En)"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.RepresentativeLastNameEn}
        value={props.RepresentativeLastNameEn}
        required={true}
        onChange={(value, res) =>
          update('RepresentativeLastNameEn', value, res)
        }
      />
      <UserInput
        name="Representative first name (En)"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.RepresentativeFirstNameEn}
        value={props.RepresentativeFirstNameEn}
        required={true}
        onChange={(value, res) =>
          update('RepresentativeFirstNameEn', value, res)
        }
      />
      <UserInput
        name="Vice representative organization name"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.ViceRepresentativeOrganizationName}
        value={_check(props.ViceRepresentativeOrganizationName)}
        required={true}
        onChange={(value, res) =>
          update('ViceRepresentativeOrganizationName', value, res)
        }
      />
      <UserInput
        name="Vice representtative last name"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.ViceRepresentativeLastName}
        value={_check(props.ViceRepresentativeLastName)}
        required={true}
        onChange={(value, res) =>
          update('ViceRepresentativeLastName', value, res)
        }
      />
      <UserInput
        name="Vice representative first name"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.ViceRepresentativeFirstName}
        value={_check(props.ViceRepresentativeFirstName)}
        required={true}
        onChange={(value, res) =>
          update('ViceRepresentativeFirstName', value, res)
        }
      />
      <UserInput
        name="Vice representative last name (En)"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.ViceRepresentativeLastNameEn}
        value={_check(props.ViceRepresentativeLastNameEn)}
        required={true}
        onChange={(value, res) =>
          update('ViceRepresentativeLastNameEn', value, res)
        }
      />
      <UserInput
        name="Vice representative first name (En)"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.ViceRepresentativeFirstNameEn}
        value={_check(props.ViceRepresentativeFirstNameEn)}
        required={true}
        onChange={(value, res) =>
          update('ViceRepresentativeFirstNameEn', value, res)
        }
      />
      <UserInput
        name="Vice representative organization registration date"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.ViceRepresentativeOrganizationRegistrationDate}
        value={_check(props.ViceRepresentativeOrganizationRegistrationDate)}
        required={true}
        onChange={(value, res) =>
          update('ViceRepresentativeOrganizationRegistrationDate', value, res)
        }
      />
      <UserInput
        name="Vice representative organization registration address"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.ViceRepresentativeOrganizationRegistrationAddress}
        value={_check(props.ViceRepresentativeOrganizationRegistrationAddress)}
        required={true}
        onChange={(value, res) =>
          update(
            'ViceRepresentativeOrganizationRegistrationAddress',
            value,
            res
          )
        }
      />
      <UserInput
        name="Applicant organization name"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.ApplicantOrganizationName}
        value={_check(props.ApplicantOrganizationName)}
        required={true}
        onChange={(value, res) =>
          update('ApplicantOrganizationName', value, res)
        }
      />
      <UserInput
        name="Applicant last name"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.ApplicantLastName}
        value={props.ApplicantLastName}
        required={true}
        onChange={(value, res) => update('ApplicantLastName', value, res)}
      />
      <UserInput
        name="Applicant first name"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.ApplicantFirstName}
        value={props.ApplicantFirstName}
        required={true}
        onChange={(value, res) => update('ApplicantFirstName', value, res)}
      />
      <UserInput
        name="Applicant last name (En)"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.ApplicantLastNameEn}
        value={props.ApplicantLastNameEn}
        required={true}
        onChange={(value, res) => update('ApplicantLastNameEn', value, res)}
      />
      <UserInput
        name="Applicant first name (En)"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.ApplicantFirstNameEn}
        value={props.ApplicantFirstNameEn}
        required={true}
        onChange={(value, res) => update('ApplicantFirstNameEn', value, res)}
      />
      <UserInput
        name="Applicant title"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.ApplicantTitle}
        value={props.ApplicantTitle}
        required={true}
        onChange={(value, res) => update('ApplicantTitle', value, res)}
      />
      <UserInput
        name="Applicant registration date"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.ApplicantRegistrationDate}
        value={props.ApplicantRegistrationDate}
        required={true}
        onChange={(value, res) => update('RepresentativeFirstName', value, res)}
      />
      <UserInput
        name="Applicat registration address"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.ApplicantRegistrationAddress}
        value={props.ApplicantRegistrationAddress}
        required={true}
        onChange={(value, res) =>
          update('ApplicantRegistrationAddress', value, res)
        }
      />
    </div>
  )
}

export default RegistrantAttribute
