import React, { useEffect } from 'react'
import useTranslation from '@/i18n'
import UserInput from '@/components/Parts/UserInput'
import Selector from '@/components/Parts/SelectorScroll'
import countryList from 'country-list'
import { useProgress, useSafeState, useErr, useTooltip } from '@/hooks'
import { GtldRegistrantAllInfo, ApiRegistrantJpParams } from '@/types'
import { prefectures, prefectures_en } from '@/utils/prefecture'
import { formatErrorTable } from '@/api/error'
import { patch } from '@/api/gtld/registrant'

const _defaultConfig: ApiRegistrantJpParams = {
  domain_name: '',
  TransactionID: '',
}

type Invalids = {
  [key: string]: {
    message: string
    params?: any
  }
}

const _defaultInvalids: Invalids = {
  ['domain_name']: { message: '' },
  ['RegistrantName']: { message: '' },
  ['RegistrantNameEn']: { message: '' },
  ['RepresentativeName']: { message: '' },
  ['CountryCode']: { message: '' },
  ['PostalCode']: { message: '' },
  ['PrefectureCode']: { message: '' },
  ['PostalAddress1']: { message: '' },
  ['PostalAddress2']: { message: '' },
  ['PostalAddress3']: { message: '' },
  ['PostalAddressEn1']: { message: '' },
  ['PostalAddressEn2']: { message: '' },
  ['PostalAddressEn3']: { message: '' },
  ['EMailAddress']: { message: '' },
  ['PhoneNumber']: { message: '' },
  ['FaxNumber']: { message: '' },
  ['RepresentativeDivision']: { message: '' },
}

function _validate(config: ApiRegistrantJpParams) {
  const _check = (word?: string) => word == null || word.length === 0

  if (_check(config.RegistrantName)) {
    return false
  }

  if (_check(config.RegistrantNameEn)) {
    return false
  }

  if (_check(config.RepresentativeName)) {
    return false
  }

  if (_check(config.PostalCode)) {
    return false
  }

  if (_check(config.EMailAddress)) {
    return false
  }

  if (_check(config.PhoneNumber)) {
    return false
  }

  if (config.CountryCode !== 'JP') {
    if (_check(config.PostalAddressEn1)) {
      return false
    }
  } else {
    if (_check(config.PrefectureCode) || config.PrefectureCode === '99') {
      return false
    }
  }
  return true
}

type Props = {
  domain_name: string
  result: GtldRegistrantAllInfo
  load: () => Promise<void>
}

function Registrant(props: Props) {
  const [configTemp, setConfigTemp] = useSafeState<ApiRegistrantJpParams>(
    _defaultConfig
  )
  const [invalids, setInvalids] = useSafeState({ ..._defaultInvalids })
  const [ready, setReady] = useSafeState(false)
  const [stateEn, setStateEn] = useSafeState('')
  const { t } = useTranslation()
  const { start, stop, inProgress } = useProgress()
  const { setErr } = useErr()
  const { setTooltip } = useTooltip()

  useEffect(() => {
    const { CountryCode, ...rest } = props.result.registrant
    const CC = CountryCode == null ? 'JP' : CountryCode

    if (props.result.registrant.PostalAddressEn1 != null) {
      setStateEn(props.result.registrant.PostalAddressEn1)
    }

    const temp = { domain_name: props.domain_name, CountryCode: CC, ...rest }
    setReady(_validate(temp))
    setConfigTemp(temp)
  }, [props.result])

  const update = (key: string, value: string, res: boolean) => {
    configTemp[key] = value
    setConfigTemp({ ...configTemp })
    setReady(_validate(configTemp))
  }

  const save = () => {
    if (inProgress) return

    const params: ApiRegistrantJpParams = { ...configTemp }

    if (params.CountryCode !== 'JP') {
      params.PrefectureCode = '99'
      params.PostalAddress1 = params.PostalAddressEn1
      params.PostalAddress2 = params.PostalAddressEn2
      params.PostalAddress3 = params.PostalAddressEn3
    } else {
      params.PostalAddressEn1 = stateEn
    }

    Object.entries(invalids).forEach(([key, _]) => (invalids[key].message = ''))
    setInvalids({ ...invalids })

    start()

    patch(params)
      .then(() => {
        props
          .load()
          .then(() => {
            setTooltip('Completed')
          })
          .catch(() => {}) // Do nothing when error
      })
      .catch((err) => {
        if (err.message === 'Format error') {
          err.params.forEach(({ key, code }) => {
            invalids[key].message = formatErrorTable[code]
          })
          setInvalids({ ...invalids })
          setErr('Invalid parameter', 422)
        } else {
          setErr(err.message, err.status)
        }
        stop()
      })
  }

  return (
    <div className="w-full mx-auto">
      <h1 className="text-blue-600 font-medium text-2xl mb-4">
        {t('Registrant information')}
      </h1>

      <UserInput
        name="The registrant name"
        max={120}
        pattern=".{1,120}"
        invalid={invalids.RegistrantName}
        value={configTemp.RegistrantName}
        required={true}
        onChange={(value, res) => update('RegistrantName', value, res)}
      />

      <UserInput
        name="The registrant name (English)"
        max={120}
        pattern=".{1,120}"
        invalid={invalids.RegistrantNameEn}
        value={configTemp.RegistrantNameEn}
        required={true}
        onChange={(value, res) => update('RegistrantNameEn', value, res)}
      />

      <UserInput
        name="The person in charge for registration"
        max={120}
        value={configTemp.RepresentativeName}
        pattern=".{1,120}"
        invalid={invalids.RepresentativeName}
        required={true}
        onChange={(value, res) => update('RepresentativeName', value, res)}
      />

      <Selector
        name="Country"
        required={true}
        selected={
          configTemp.CountryCode != null
            ? countryList.getName(configTemp.CountryCode)
            : countryList.getName('JP')
        }
        options={countryList.getNames()}
        onChange={(value) => {
          const countryCode = countryList.getCode(
            value === '日本' ? 'Japan' : value
          )
          update('CountryCode', countryCode, true)

          if (configTemp.PrefectureCode === '99') {
            setStateEn('')
          }
        }} //Alaways true
      />

      <UserInput
        name="Postal code"
        max={8}
        pattern=".{1,8}"
        invalid={invalids.PostalCode}
        value={configTemp.PostalCode}
        required={true}
        onChange={(value, res) => update('PostalCode', value, res)}
        placeholder="123-4567"
      />

      {configTemp.CountryCode === 'JP' && (
        <>
          <Selector
            name="Prefecture"
            required={true}
            selected={
              configTemp.PrefectureCode === '99'
                ? undefined
                : prefectures[Number(configTemp.PrefectureCode) - 1]
            }
            options={prefectures}
            onChange={(value) => {
              const id = prefectures.findIndex((pre) => pre === value)
              setStateEn(prefectures_en[Number(id)])
              update('PrefectureCode', String(Number(id) + 1), true)
            }} //Alaways true
          />
          <UserInput
            name={'City'}
            max={60}
            pattern=".{1,60}"
            invalid={invalids.PostalAddress1}
            value={configTemp.PostalAddress1}
            required={true}
            onChange={(value, res) => update('PostalAddress1', value, res)}
            placeholder=""
          />
          <UserInput
            name={'Address 1'}
            max={60}
            pattern=".{1,60}"
            invalid={invalids.PostalAddress2}
            value={configTemp.PostalAddress2}
            required={true}
            onChange={(value, res) => update('PostalAddress2', value, res)}
          />
          <UserInput
            name={'Address 2'}
            max={60}
            pattern=".{1,60}"
            invalid={invalids.PostalAddress3}
            value={configTemp.PostalAddress3}
            required={true}
            onChange={(value, res) => update('PostalAddress3', value, res)}
          />
        </>
      )}

      {configTemp.CountryCode === 'JP' && (
        <div className="mb-8">
          <p className="text-gray-800 text-sm mr-2 mb-2">State (English)</p>
          <p className="text-gray-800 text-sm mr-2 px-2">{stateEn}</p>
        </div>
      )}

      {configTemp.CountryCode !== 'JP' && (
        <UserInput
          name="State (English)"
          max={60}
          pattern=".{1,60}"
          invalid={invalids.PostalAddressEn1}
          value={
            configTemp.PostalAddressEn1 != null
              ? configTemp.PostalAddressEn1
              : ''
          }
          required={true}
          onChange={(value, res) => update('PostalAddressEn1', value, res)}
        />
      )}

      <UserInput
        name="City (English)"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.PostalAddressEn2}
        value={
          configTemp.PostalAddressEn2 != null ? configTemp.PostalAddressEn2 : ''
        }
        required={true}
        onChange={(value, res) => update('PostalAddressEn2', value, res)}
      />

      <UserInput
        name="Street (English)"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.PostalAddressEn3}
        required={true}
        value={
          configTemp.PostalAddressEn3 != null ? configTemp.PostalAddressEn3 : ''
        }
        onChange={(value, res) => update('PostalAddressEn3', value, res)}
      />

      <UserInput
        name="Division"
        max={120}
        pattern=".{1,120}"
        invalid={invalids.RepresentativeDivision}
        value={
          configTemp.RepresentativeDivision == null
            ? ''
            : configTemp.RepresentativeDivision
        }
        required={false}
        onChange={(value, res) => update('RepresentativeDivision', value, res)}
      />

      <UserInput
        name="Email"
        max={80}
        pattern=".{1,80}"
        invalid={invalids.EMailAddress}
        value={configTemp.EMailAddress}
        required={true}
        onChange={(value, res) => update('EMailAddress', value, res)}
      />

      <UserInput
        name="Phone Number"
        max={40}
        pattern=".{1,40}"
        invalid={invalids.PhoneNumber}
        value={configTemp.PhoneNumber}
        required={true}
        onChange={(value, res) => update('PhoneNumber', value, res)}
      />

      <UserInput
        name="Fax Number"
        max={40}
        pattern=".{1,40}"
        invalid={invalids.FaxNumber}
        value={configTemp.FaxNumber == null ? '' : configTemp.FaxNumber}
        required={false}
        onChange={(value, res) => update('FaxNumber', value, res)}
      />

      <div className="text-right">
        <button
          disabled={inProgress || !ready}
          className={`w-32 rounded-sm ${
            !inProgress && ready ? 'bg-blue-500' : 'bg-gray-500'
          } text-white`}
          onClick={save}
        >
          {t('Update')}
        </button>
      </div>
    </div>
  )
}

export default Registrant
