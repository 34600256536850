import React, { useEffect } from 'react'
import useTranslation from '@/i18n'
import { useProgress, useSafeState } from '@/hooks'
import Value from './Value'
import FlexListFrame from '../FlexListFrame'
import BtnAdd from '../ButtonAdd'

type Props = {
  title: string
  vals: string[]
  errorMsg: { id: number; msg: string }[]
  change: (vals: string[]) => void
  validate: (value: string) => boolean
  required?: boolean
}

const MultiValue = ({
  title,
  vals,
  errorMsg,
  change,
  validate,
  required,
}: Props) => {
  const [error, setError] = useSafeState('')
  const [disAdd, setDisAdd] = useSafeState(true)
  const { t } = useTranslation()
  const { inProgress } = useProgress()

  useEffect(() => {
    vals.length < 32 && setDisAdd(vals.some((val) => !validate(val)))
  }, [vals])

  const update = (ruaList: string[]) => {
    change([...ruaList])
  }

  const add = () => {
    if (inProgress || disAdd) return

    if (vals.length === 32) {
      setError('Reached the limit')
      setDisAdd(true)
      return
    } else {
      setError('')
    }

    vals.push('')
    update(vals)
    setError('')
  }

  const del = (id: number) => {
    const res = vals.filter((_, index) => {
      return index !== id
    })
    update(res)
    setError('')
  }

  const changeVal = (id: number, value: string) => {
    vals[id] = value
    update(vals)
  }

  const Title = () => (
    <p className="text-md font-medium text-gray-900">{t(title)}</p>
  )

  const getErrMsg = (index: number) => {
    const res = errorMsg.findIndex(({ id }) => id === index)
    if (res !== -1) return errorMsg[res].msg
    return ''
  }

  const List = (data: string, index: number, total: number) => {
    const err = getErrMsg(index)
    return (
      <Value
        id={index}
        value={data}
        change={changeVal}
        total={total}
        del={del}
        addNew={add}
        validate={validate}
        errorMsg={err}
        key={index}
        required={required}
      />
    )
  }

  const Input = () => <BtnAdd disAdd={disAdd} add={add} />

  return (
    <FlexListFrame
      title={Title}
      listData={vals}
      list={List}
      input={Input}
      error={error}
    />
  )
}

export default MultiValue
