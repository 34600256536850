import { axios, getAuthorization } from '@/api/axios'
import { withRefreshToken } from '@/auth'
import { errConverter } from '../error'
import { ApiRegistrantJpParams } from '@/types'

const patchGtldRegistrant = (params?: ApiRegistrantJpParams): Promise<void> =>
  new Promise<void>((resolve, reject) =>
    axios
      .patch<void>('/gtld/registrant', params, {
        headers: getAuthorization(),
      })
      .then(() => resolve())
      .catch((err) => reject(errConverter(err)))
  )

const patch = withRefreshToken(patchGtldRegistrant)

export { patch }
