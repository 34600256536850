import { axios, getAuthorization } from '@/api/axios'
import { withRefreshToken } from '@/auth'
import { errConverter } from '../error'
import { DomainAllInfoResult } from '@/types'

const getDomainListAllInfo = (id?: string): Promise<DomainAllInfoResult> =>
  new Promise<DomainAllInfoResult>((resolve, reject) =>
    axios
      .get<DomainAllInfoResult>(`/domain`, {
        //TODO: もはやconfigでないので名前変える
        headers: getAuthorization(),
        params: {
          id,
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(errConverter(err))
      })
  )

const get = withRefreshToken(getDomainListAllInfo)

export { get }
