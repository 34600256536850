import React, { useEffect, useRef, useState } from 'react'
import ErrorMsg from '../ErrorMsg'
import { useProgress } from '@/hooks'
import { IoTrash } from 'react-icons/io5'
import { border } from '@/utils'

type IpProps = {
  id: number
  value: string
  change: (id: number, value: string) => void
  del: (id: number) => void
  validate: (value: string) => boolean
  addNew: () => void
  total: number
  errorMsg: string
  required?: boolean
}

const Value = ({
  value,
  change,
  id,
  del,
  addNew,
  validate,
  total,
  errorMsg,
  required,
}: IpProps) => {
  const ref = useRef<HTMLInputElement | null>(null)

  const [error, setError] = useState('')
  const { inProgress } = useProgress()

  useEffect(() => {
    if (ref != null && ref.current! && value.length === 0) {
      ref.current.focus()
    }
  }, [])

  const onchange = (e) => {
    e.preventDefault()
    const val = e.target.value
    if (val.length != 0 && !validate(val)) {
      setError(errorMsg)
    } else {
      setError('')
    }
    change(id, val)
  }

  const keyDown = (e) => {
    if (inProgress) return

    if (e.keyCode === 13) {
      addNew()
    }
  }

  const onDel = () => {
    if (inProgress) return
    setError('')
    del(id)
  }

  return (
    <>
      <div className="mt-1 flex items-center text-md text-gray-800">
        <input
          ref={ref}
          disabled={inProgress}
          maxLength={40}
          className={`focus:outline-none border-b border-transparent px-2 ${border(
            value,
            error,
            required
          )}  w-full mr-1`}
          value={value}
          onChange={(e) => onchange(e)}
          onKeyDown={keyDown}
        />
        <IoTrash
          className={`${total === 1 &&
            'hidden'} text-gray-700 mr-1 cursor-pointer`}
          onClick={onDel}
        />
      </div>

      <ErrorMsg error={error} />
    </>
  )
}

export default Value
