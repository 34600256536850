import React, { useEffect } from 'react'
import useTranslation from '@/i18n'
import UserInput from '@/components/Parts/UserInput'
import Selector from '@/components/Parts/SelectorScroll'
import { useProgress, useSafeState, useErr, useTooltip } from '@/hooks'
import { GtldRegistrantAllInfo, ApiRegistrantJpParams } from '@/types'
import { prefectures } from '@/utils/prefecture'
import { formatErrorTable } from '@/api/error'
import { patch } from '@/api/gtld/registrant'
import Contact from './Contact'
import ButtonRounded from '@/components/Parts/ButtonRounded'

const _defaultConfig: ApiRegistrantJpParams = {
  domain_name: '',
  TransactionID: '',
}

type Invalids = {
  [key: string]: {
    message: string
    params?: any
  }
}

const _defaultInvalids: Invalids = {
  ['domain_name']: { message: '' },
  ['RegistrantName']: { message: '' },
  ['RegistrantNameEn']: { message: '' },
  ['RepresentativeName']: { message: '' },
  ['CountryCode']: { message: '' },
  ['PostalCode']: { message: '' },
  ['PrefectureCode']: { message: '' },
  ['PostalAddress1']: { message: '' },
  ['PostalAddress2']: { message: '' },
  ['PostalAddress3']: { message: '' },
  ['EMailAddress']: { message: '' },
  ['PhoneNumber']: { message: '' },
  ['FaxNumber']: { message: '' },
  ['RepresentativeDivision']: { message: '' },
}

function _validate(config: ApiRegistrantJpParams) {
  if (config.PostalCode == null) {
    return false
  }

  if (config.RepresentativeName == null) {
    return false
  }

  if (config.EMailAddress == null) {
    return false
  }

  if (config.RegistrantName == null) {
    return false
  }

  if (config.RegistrantNameEn == null) {
    return false
  }

  if (config.PostalAddress1 == null) {
    return false
  }

  return true
}

type Props = {
  domain_name: string
  load: () => Promise<void>
  result: GtldRegistrantAllInfo
}

function Registrant(props: Props) {
  const [configTemp, setConfigTemp] = useSafeState<ApiRegistrantJpParams>(
    _defaultConfig
  )
  const [invalids, setInvalids] = useSafeState({ ..._defaultInvalids })
  const [ready, setReady] = useSafeState(false)
  const { t } = useTranslation()
  const { start, stop, inProgress } = useProgress()
  const { setErr } = useErr()
  const { setTooltip } = useTooltip()

  useEffect(() => {
    const { CountryCode, ...rest } = props.result.registrant
    const CC = CountryCode == null ? 'JP' : CountryCode
    const temp = { domain_name: props.domain_name, CountryCode: CC, ...rest }

    setReady(_validate(temp))
    setConfigTemp(temp)
  }, [])

  const update = (key: string, value: string, res: boolean) => {
    configTemp[key] = value
    setConfigTemp({ ...configTemp })

    setReady(_validate(configTemp))
  }

  const save = () => {
    if (inProgress) return

    const params = { ...configTemp }

    Object.entries(invalids).forEach(([key, _]) => (invalids[key].message = ''))
    setInvalids({ ...invalids })

    if (params.CountryCode === 'JP') {
      params.CountryCode = undefined
    }

    params.PostalAddressEn1 = undefined
    params.PostalAddressEn2 = undefined
    params.PostalAddressEn3 = undefined

    start(0)

    patch(params)
      .then(() => {
        props
          .load()
          .then(() => {
            stop()
            setTooltip('Completed')
          })
          .catch(() => {
            stop()
          }) // Do nothing when error
      })
      .catch((err) => {
        if (err.message === 'Format error') {
          err.params.forEach(({ key, code }) => {
            invalids[key].message = formatErrorTable[code]
          })
          setInvalids({ ...invalids })
          setErr('Invalid parameter', 422)
        } else {
          setErr(err.message, err.status)
        }
        stop()
      })
  }

  return (
    <div className="w-full mx-auto">
      <h1 className="text-blue-600 font-medium text-2xl mb-4">
        {t('Contact information')}
      </h1>
      <Contact contact={props.result.contact!} reload={props.load} />

      <h1 className="text-blue-600 font-medium text-2xl mb-4">
        {t('Registrant information')}
      </h1>

      <UserInput
        name="The registrant name"
        max={120}
        pattern=".{1,120}"
        invalid={invalids.RegistrantName}
        value={configTemp.RegistrantName}
        required={true}
        onChange={(value, res) => update('RegistrantName', value, res)}
      />

      <UserInput
        name="The registrant name (English)"
        max={120}
        pattern=".{1,120}"
        invalid={invalids.RegistrantNameEn}
        value={configTemp.RegistrantNameEn}
        required={true}
        onChange={(value, res) => update('RegistrantNameEn', value, res)}
      />

      <UserInput
        name="The person in charge for registration"
        max={120}
        value={configTemp.RepresentativeName}
        pattern=".{1,120}"
        invalid={invalids.RepresentativeName}
        required={true}
        onChange={(value, res) => update('RepresentativeName', value, res)}
      />

      <div className="mb-8">
        <p className="text-gray-800 text-sm mr-2 mb-2">{t('Country')}</p>
        <p className="text-gray-800 text-sm mr-2 px-2">{t('Japan')}</p>
      </div>

      <UserInput
        name="Postal code"
        max={8}
        pattern=".{1,8}"
        invalid={invalids.PostalCode}
        value={configTemp.PostalCode}
        required={true}
        onChange={(value, res) => update('PostalCode', value, res)}
        placeholder="123-4567"
      />

      <Selector
        name="Prefecture"
        required={true}
        selected={
          configTemp.PrefectureCode != null &&
          configTemp.PrefectureCode.length === 0
            ? undefined
            : prefectures[Number(configTemp.PrefectureCode) - 1]
        }
        options={prefectures}
        onChange={(value) => update('PrefectureCode', value, true)} //Alaways true
      />

      <UserInput
        name="City"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.PostalAddress1}
        value={configTemp.PostalAddress1}
        required={true}
        onChange={(value, res) => update('PostalAddress1', value, res)}
        placeholder=""
      />

      <UserInput
        name="Address 1"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.PostalAddress2}
        value={configTemp.PostalAddress2}
        required={true}
        onChange={(value, res) => update('PostalAddress2', value, res)}
      />

      <UserInput
        name="Address 2"
        max={60}
        pattern=".{1,60}"
        invalid={invalids.PostalAddress3}
        value={configTemp.PostalAddress3}
        required={false}
        onChange={(value, res) => update('PostalAddress3', value, res)}
      />

      <UserInput
        name="Division"
        max={120}
        pattern=".{1,120}"
        invalid={invalids.RepresentativeDivision}
        value={
          configTemp.RepresentativeDivision == null
            ? ''
            : configTemp.RepresentativeDivision
        }
        required={false}
        onChange={(value, res) => update('RepresentativeDivision', value, res)}
      />

      <UserInput
        name="Email"
        max={80}
        pattern=".{1,80}"
        invalid={invalids.EMailAddress}
        value={configTemp.EMailAddress}
        required={true}
        onChange={(value, res) => update('EMailAddress', value, res)}
      />

      <UserInput
        name="Phone Number"
        max={40}
        pattern=".{1,40}"
        invalid={invalids.PhoneNumber}
        value={configTemp.PhoneNumber}
        required={true}
        onChange={(value, res) => update('PhoneNumber', value, res)}
      />

      <UserInput
        name="Fax Number"
        max={40}
        pattern=".{1,40}"
        invalid={invalids.FaxNumber}
        value={configTemp.FaxNumber == null ? '' : configTemp.FaxNumber}
        required={false}
        onChange={(value, res) => update('FaxNumber', value, res)}
      />

      <div className="text-right">
        <ButtonRounded onClick={save} disabled={!ready} name="Update" />
      </div>
    </div>
  )
}

export default Registrant
