import React from 'react'
import { GtldRegistrantAllInfo } from '@/types'
import Jp from './Jp'
import Gtld from './Gtld'

type Props = {
  domain_name: string
  result: GtldRegistrantAllInfo
  load: () => Promise<void>
}

function Registrant(props: Props) {
  const isJpDomain = props.domain_name.toLowerCase().endsWith('.jp')

  if (isJpDomain) {
    return (
      <Jp
        load={props.load}
        domain_name={props.domain_name}
        result={props.result}
      />
    )
  }

  return (
    <Gtld
      load={props.load}
      domain_name={props.domain_name}
      result={props.result}
    />
  )
}

export default Registrant
