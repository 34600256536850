import React from 'react'
import useTranslation from '@/i18n'
import { useErr, useProgress } from '@/hooks'
import { post } from '@/api/domain/config'
import { DomainConfigResult } from '@/types'
import ButtonRounded from '@/components/Parts/ButtonRounded'

type Props = {
  config: DomainConfigResult
  update: (result: DomainConfigResult) => void
  reload: () => void
}

function Internal(props: Props) {
  const { t } = useTranslation()
  const { start, stop } = useProgress()
  const { setErr } = useErr()

  const update = () => {
    const params = {
      id: props.config.id,
      use_qt_dns: true,
      name_servers: [],
      signing_keys: [],
    }

    start()
    post(params)
      .then(() => props.reload())
      .catch((err) => {
        setErr(err.status, err.message)
        stop()
      })
  }

  return (
    <div className="w-full">
      <div>{t('ExplainAboutQualitiaDns')}</div>
      <div className="mt-4">
        <a href={`${process.env.QT_DNS_URL}/zones`} className="text-blue-500">
          {t('Go to QUALITIA DNS')}
        </a>
      </div>
      <div className="mt-4 text-right">
        <ButtonRounded name="Update" onClick={update} disabled={false} />
      </div>
    </div>
  )
}

export default Internal
