import { axios, getAuthorization } from '@/api/axios'
import { withRefreshToken } from '@/auth'
import { errConverter } from '../error'
import {
  DomainInfoResult,
  DomainConfigResult,
  DomainConfigParams,
} from '@/types'

const getDomainListConfig = (id?: string): Promise<DomainInfoResult> =>
  new Promise<DomainInfoResult>((resolve, reject) =>
    axios
      .get<DomainInfoResult>(`/domain/config`, {
        //TODO: もはやconfigでないので名前変える
        headers: getAuthorization(),
        params: {
          id,
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(errConverter(err))
      })
  )

const get = withRefreshToken(getDomainListConfig)

const postDomainListConfig = (
  params?: DomainConfigParams
): Promise<DomainConfigResult> =>
  new Promise<DomainConfigResult>((resolve, reject) =>
    axios
      .post<DomainConfigResult>('/domain/config', params, {
        headers: getAuthorization(),
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(errConverter(err)))
  )

const post = withRefreshToken(postDomainListConfig)

export { get, post }
