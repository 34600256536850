import React, { useEffect, useRef } from 'react'
import { ContactGtld, ApiContactGtldParams } from '@/types'
import useTranslation from '@/i18n'
import {
  useProgress,
  useSafeState,
  useErr,
  useTooltip,
  useRightBar,
  useHeight,
} from '@/hooks'
import { prefectures_en } from '@/utils/prefecture'
import { patch } from '@/api/gtld/contact'
import clonedeep from 'lodash.clonedeep'
import { formatErrorTable } from '@/api/error'
import {
  defaultGtldContact,
  defaultGtldContactInvalids,
  FOREIN_COUNTRY,
  clean,
} from '@/utils'
import countryList from 'country-list'
import Inputs from './Inputs'
import ButtonRounded from '@/components/Parts/ButtonRounded'

type Props = {
  contact: ContactGtld
  reload: () => Promise<void>
}

function _convert(contact: ApiContactGtldParams) {
  if (contact.EMailAddress == null || contact.PhoneNumber == null) {
    throw Error('FIXME')
  }

  const email = contact.EMailAddress.filter((email) => email.length > 0)
  const phone = contact.PhoneNumber.filter((phone) => phone.length > 0)

  if (contact.PrefectureCode === FOREIN_COUNTRY) {
    contact.PostalAddress1 = contact.PostalAddressEn1
    contact.PostalAddress2 = contact.PostalAddressEn2
    contact.PostalAddress3 = contact.PostalAddressEn3
  } else {
    if (process.env.API_JPRS_GTLD)
      contact.CountryCode = countryList.getCode('Japan')
    contact.PostalAddressEn1 =
      prefectures_en[Number(contact.PrefectureCode) - 1]
  }

  const { EMailAddress, PhoneNumber, ...rest } = contact

  return { EMailAddress: email, PhoneNumber: phone, ...rest }
}

function ContactEditor(props: Props) {
  const [tempContact, setTempContact] = useSafeState(
    clonedeep(defaultGtldContact)
  )
  const [disable, setDisable] = useSafeState(false)
  const { start, stop } = useProgress()
  const [invalids, setInvalids] = useSafeState(
    clonedeep(defaultGtldContactInvalids)
  )
  const { setErr } = useErr()
  const { setTooltip } = useTooltip()
  const { closeRightBar } = useRightBar()
  const { t } = useTranslation()

  useEffect(() => {
    const {
      TransactionID,
      CreateTime,
      LastUpdateTime,
      EMailAddress,
      PhoneNumber,
      ...rest
    } = props.contact

    EMailAddress.forEach((email, index) => {
      tempContact.EMailAddress[index] = email
    })

    PhoneNumber.forEach((phone, index) => {
      tempContact.PhoneNumber[index] = phone
    })

    const res = clean({ ...rest })

    setTempContact(
      clonedeep({
        ...res,
        EMailAddress: tempContact.EMailAddress,
        PhoneNumber: tempContact.PhoneNumber,
      })
    )
  }, [])

  useEffect(() => {
    setDisable(
      (process.env.API_JPRS_GTLD &&
        tempContact.PrefectureCode == FOREIN_COUNTRY &&
        tempContact.CountryCode == null) ||
        tempContact.ContactName === '' ||
        tempContact.ContactNameEn === ''
    )
  }, [tempContact])

  const save = () => {
    start(0)
    setInvalids(clonedeep(defaultGtldContactInvalids))

    const contact = _convert(tempContact)

    patch(contact)
      .then(() => {
        props
          .reload()
          .then(() => {
            stop()
            closeRightBar()
            setTooltip('Completed')
          })
          .catch((err) => {
            stop()
            setErr(err.message, err.status)
          })
      })
      .catch((err) => {
        if (err.status === 422 && err.subCode === 15) {
          err.params.forEach(({ key, code, index }) => {
            invalids[key].message = formatErrorTable[code]
            invalids[key].params = index
          })
          setInvalids({ ...invalids })
          setErr('Invalid parameter', 422)
        } else {
          setErr(err.message, err.status)
        }
        stop()
      })
  }

  return (
    <div className="mb-8">
      <div className="py-2 w-full ">
        <p className="text-blue-500 mt-4 text-md font-medium">{t('AGOP ID')}</p>
        <p className="font-medium text-gray-800 mb-4">
          {tempContact.ContactID}
        </p>

        <Inputs
          invalids={invalids}
          contacts={tempContact}
          setContact={setTempContact}
        />
      </div>
      <div className="text-right mt-2 mr-4">
        <ButtonRounded name={'Update'} onClick={save} disabled={disable} />
      </div>
    </div>
  )
}

export default ContactEditor
