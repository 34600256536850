export function validateDsRecord(ds: string) {
  console.log(112323232, ds)
  const result = ds.split(' ')

  if (result.length !== 4) {
    return false
  }

  const res = result
    .map((label) => label.trim())
    .filter((label) => label.length === 0)

  return res.length === 0
}
