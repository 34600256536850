import React from 'react'
import useTranslation from '@/i18n'
import TextareaAutosize from 'react-textarea-autosize'
import { useProgress } from '@/hooks'
import ErrorMsg from '@/components/Parts/ErrorMsg'

type Props = {
  text: string
  onChange: (text: string) => void
  error: string
}

function NameServer(props: Props) {
  const { t } = useTranslation()
  const { inProgress } = useProgress()

  const change = (e) => {
    props.onChange(e.target.value)
  }

  return (
    <>
      <h1 className="text-xl text-gray-800 mt-4 font-medium">
        {t('Name servers')}
      </h1>
      <TextareaAutosize
        disabled={inProgress}
        maxLength={200}
        maxRows={10}
        minRows={5}
        value={props.text}
        onChange={change}
        className="text-sm text-gray-900 resize-none w-full border border-gray-500 outline-none focus:outline-none px-1"
      />
      {props.error.length > 0 && <ErrorMsg error={props.error} />}
    </>
  )
}

export default NameServer
