import React, { ReactNode } from 'react'
import useTranslation from '@/i18n'

type Props = {
  error: string
  params?: any
}

const Error = ({ error, params }: Props) => {
  const { t } = useTranslation()
  return <p className="text-red-500 text-xs mb-2">{t(error, params)}</p>
}

export default Error
