import React, { useEffect } from 'react'
import useTranslation from '@/i18n'
import ButtonToggle from '@/components/Parts/ButtonToggle'
import {
  useErr,
  useModal,
  useProgress,
  useSafeState,
  useWaitSignIn,
} from '@/hooks'
import Internal from '@/components/DomainList/Config/Internal'
import External from '@/components/DomainList/Config/External'
import { get } from '@/api/domain'
import {
  DomainConfigResult,
  RegistrantAtrributeResult,
  GtldRegistrantAllInfo,
} from '@/types'
import Reload from '@/components/Parts/Reload'
import * as queryString from 'query-string'
import { Link, PageRendererProps } from 'gatsby'
import { IoIosArrowBack } from 'react-icons/io'
import Gtld from '@/components/DomainList/Registrant/Gtld'
import Attribute from '@/components/DomainList/Registrant/Attribute'
import { isValidDomain } from '@/utils'

function DomainConfig({ location }: PageRendererProps) {
  const [forbidden, setForbidden] = useSafeState(false)
  const { closeModal } = useModal()
  const { t } = useTranslation()
  const [config, setConfig] = useSafeState<DomainConfigResult | null>(null)
  const [gtld, setGtld] = useSafeState<GtldRegistrantAllInfo | null>(null)
  const [
    attribute,
    setAttribute,
  ] = useSafeState<RegistrantAtrributeResult | null>(null)
  const waitAuth = useWaitSignIn()
  const { start, stop, inProgress } = useProgress()
  const { setErr } = useErr()

  const { id } = queryString.parse(location.search)
  const domain_id: string = typeof id === 'string' ? id : ''

  useEffect(() => {
    waitAuth(load)
  }, [])

  const load = () => {
    return new Promise<void>((resolve, reject) => {
      if (domain_id != '') {
        start(0)
        get(domain_id)
          .then((res) => {
            const config = res.config
            setConfig({ ...config })
            if (isValidDomain(config.status)) {
              res.gtld != null && setGtld({ ...res.gtld })
              res.attribute != null && setAttribute({ ...res.attribute })
            }
            setForbidden(false)
            closeModal()
            stop()
            resolve()
          })
          .catch((err) => {
            setErr(err.message, err.status)
            setForbidden(true)
            closeModal()
            stop()
            reject()
          })
      }
    })
  }

  const update = (result: DomainConfigResult) => setConfig({ ...result })

  if (config == null) {
    return (
      <div className="w-full text-center">
        <h1 className="text-2xl text-gray-500 font-medium mt-4">
          {t('Now loading')}
        </h1>
      </div>
    )
  }

  if (forbidden) {
    return (
      <div>
        <h1 className="text-2xl text-red-500 font-medium">
          {t('Unauthorized')}
        </h1>
      </div>
    )
  }

  if (!isValidDomain(config.status)) {
    return (
      <div>
        <h1 className="text-2xl text-red-500 font-medium">
          {t('This domain is not ready')}
        </h1>
      </div>
    )
  }
  return (
    <div>
      <Link
        to="/domain-list"
        className="flex items-center text-2xl font-medium text-gray-500"
      >
        <IoIosArrowBack className="mr-2 " />
        {config.domain_name}
      </Link>
      <div className="mt-4 w-full xl:w-1/2 mx-auto">
        <Reload load={load} />

        <p className="text-blue-600 text-2xl font-medium">
          {t('Domain manager')}
        </p>

        <div className="mt-4">
          <ButtonToggle
            label={'Use QUALITIA DNS for managing this domain'}
            toggle={config.use_qt_dns}
            setToggle={() => {
              if (!inProgress) {
                config.use_qt_dns = !config.use_qt_dns
                setConfig({ ...config })
              }
            }}
          />
        </div>
        <div className="mt-4">
          {!config.use_qt_dns && (
            <External config={config} update={update} reload={load} />
          )}
          {config.use_qt_dns && (
            <Internal config={config} update={update} reload={load} />
          )}
        </div>
        <div className="mt-8">
          {gtld != null && (
            <div className="mt-4">
              <Gtld
                load={load}
                result={gtld}
                domain_name={config.domain_name}
              />
            </div>
          )}
          {attribute != null && (
            <div className="mt-4">
              <Attribute {...attribute} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DomainConfig
